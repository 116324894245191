import axios from "axios";

export const GET_PLAYLISTS = "GET_PLAYLISTS";
export const GET_POPULAR_VIDEOS_PLAYLIST = "GET_POPULAR_VIDEOS_PLAYLIST";
export const GET_RECENT_VIDEOS_PLAYLIST = "GET_RECENT_VIDEOS_PLAYLIST";
export const GET_PRIVATE_PLAYLISTS = "GET_PRIVATE_PLAYLISTS";

export const getPlaylists = (page = 0, quantity = 6, lang = 'en') => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}gethomeplaylists&n=${quantity}&p=${page}&lg=${lang}`)
			.then((res) => dispatch({type: GET_PLAYLISTS, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};

export const getPopularVideoPlaylist = (page = 0, quantity = 6, lang = 'en') => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}getPopularVideos&n=${quantity}&p=${page}&lg=${lang}`)
			.then((res) => dispatch({type: GET_POPULAR_VIDEOS_PLAYLIST, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};

export const getRecentVideoPlaylist = (page = 0, quantity = 6, lang = 'en') => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}getRecentVideos&n=${quantity}&p=${page}&lg=${lang}`)
			.then((res) => dispatch({type: GET_RECENT_VIDEOS_PLAYLIST, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};

export const getPrivatePlaylists = (page = 0, quantity = 6, lang = 'en') => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			let form = new FormData();
			form.append("key", loginToken);
			form.append("n", quantity);
			form.append("p", page);
			form.append("lg", lang);

			await axios
				.post(`${process.env.REACT_APP_API_URL}getPrivatePlaylists`, form)
				.then((res) => dispatch({type: GET_PRIVATE_PLAYLISTS, payload: res.data}))
				.catch((error) => {
					console.log(error)
				});
		}
	};
};