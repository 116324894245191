import React, {useContext, useEffect, useRef, useState} from "react";
import {NavLink} from "react-router-dom";
import {isEmpty} from "./Utils";
import Slider from "react-slick";
import RightArrow from "./slider/RightArrow";
import LeftArrow from "./slider/LeftArrow";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {MyContext} from "../contexts/MyContext";
import {getVideoFavorite} from "../actions/videos.action";
import {toggleModal} from "../actions/modal.action";
import Video from "./Video";

const PlaylistFavoriteVideos = (props) => {
	const { name, videos, hideSeeMore, showCount, nextPage } = props;
	const sliderRef = useRef(null);
	const slider = useRef();
	const dispatch = useDispatch();
	const config = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const [width, setWidth] = useState();
	const [videoArray, setVideoArray] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(5);
	const [hasNexPage, setHasNextPage] = useState(nextPage);
	const [countPage, setCountPage] = useState(1);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translate;
	const { rootState, addToVideoFavorite } = useContext(MyContext);
	const { isAuth } = rootState;

	const getWidth = () => {
		if (sliderRef.current && sliderRef.current.clientWidth) {
			const newWidth = sliderRef.current.clientWidth;
			setWidth(newWidth);
		}
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			window.dispatchEvent(new Event("resize"));
		}, 150);
		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		if (sliderRef.current && sliderRef.current.clientWidth) {
			getWidth();
		}
	}, []);

	useEffect(() => {
		window.addEventListener("resize", getWidth);
		return () => {
			window.removeEventListener("resize", getWidth);
		};
	}, []);

	let settings = {
		infinite: false,
		speed: 500,
		slidesToShow: itemsPerPage,
		slidesToScroll: itemsPerPage,
		prevArrow: <LeftArrow />,
		nextArrow: <RightArrow />,
		draggable: true,
		initialSlide: 0,
		centerMode: false,
		afterChange: (index) => {
			if(document.querySelector(".d-none.arrow-slideshow.right")){
				nextPageHandler();
			}
		}
	};

	useEffect(() => {
		setVideoArray(videos)
	}, [videos]);

	useEffect(() => {
		if (!isEmpty(width) && Math.floor(width / 200) <= 5) {
			setItemsPerPage(Math.floor(width / 200));
		} else {
			setItemsPerPage(5);
		}
	}, [width]);

	const nextPageHandler = async () => {
		if (hasNexPage !== null) {
			const loginToken = localStorage.getItem("loginToken");
			if (loginToken) {
				let form = new FormData();
				form.append("key", loginToken);
				form.append("p", countPage.toString());
				form.append("t", "3");
				form.append("n", "10");
				await axios.post(`${process.env.REACT_APP_API_URL}getVideoFavorite`, form)
					.then((res) => {
						setHasNextPage(res.data.nextpage);
						setCountPage(countPage + 1);
						setVideoArray([...videoArray, ...res.data.videos]);
					})
					.catch((error) => {
						console.log(error)
					});
			}
		}

	};

	const handleFavorite = async (id, keyArray) => {

		await addToVideoFavorite(id)
			.then((res) => {
				if (res.result === "ok") {
					const arrayClone = Array.from(videoArray);
					arrayClone.splice(keyArray, 1)
					setVideoArray(arrayClone);
					if (slider) {
						slider.current.slickGoTo(0);

						let text = translations && !isEmpty(translations["video_removed_from_list"])
							? translations["video_removed_from_list"]
							: "Your video has been removed from your list!"

						dispatch(toggleModal(
							{
								opened: true,
								success: true,
								text: text
							})
						)
					}
					if (isAuth) {
						dispatch(getVideoFavorite("0", "3", "10"))
					}
				}
			})
	};

	return (
		<div className="playlist-videos filter white">
			<div className="titles">
				<section
					className="flex-between m-auto"
				>
					<h3>
						{!isEmpty(name) && name}
						{!isEmpty(showCount) && (
							<span className="count">{showCount}</span>
						)}
					</h3>
					{!hideSeeMore && (
						<NavLink to="/videos" className="item-menu" style={{color: config?.general_text_color}}>
							<h5>
								{translations && !isEmpty(translations["see_more"])
									? translations["see_more"]
									: "See more"
								}
								<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0.665363 6.66536L8.7787 6.66536L5.05203 10.392L5.9987 11.332L11.332 5.9987L5.9987 0.665364L5.0587 1.60536L8.7787 5.33203L0.665363 5.33203L0.665363 6.66536Z" fill="#F41B3B" stroke="#F41B3B" strokeWidth="0.2"/>
								</svg>
							</h5>
						</NavLink>
					)}
				</section>
			</div>
			<div ref={sliderRef}>
				<Slider ref={slider} {...settings} className="videos-container hide-scroll customScroll">
					{!isEmpty(videoArray) &&
						videoArray.map((video, key) => {
							return (
								<Video video={video} key={key} handleFavorite={handleFavorite}/>
							);
						})}
				</Slider>
			</div>
		</div>
	);
};

export default PlaylistFavoriteVideos;
